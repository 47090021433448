import { useState, useEffect } from 'react'
const User = ({user, displaySaveCallback, onSubmitUserForm, setCurrentView, setUser, displayErrorCallback}) => {

    const [username, setUsername] = useState(user !== null ? user.email : '')
    const [password, setPassword] = useState('')
    const [roles, setRoles] = useState(user !== null ? user.roles : ['ROLE_USER'])
    const [reportMailUser, setReportMailUser] = useState(user !== null ? user.reportMailUser : false)

    const handleChange = (event) => {

        if(event.target.checked && roles.indexOf(event.target.value) === -1) {
           roles.push(event.target.value)
        } else if (roles.indexOf(event.target.value) !== -1) {
            roles.splice(roles.indexOf(event.target.value), 1);
        }

    }

    useEffect(() => {
        let check = document.getElementById('roleAdmin');
        check.checked = roles.indexOf('ROLE_ADMIN') !== -1;
        let check2 = document.getElementById('reportMailUser');
        check2.checked = reportMailUser;
    }, [])


    const onSubmit = (e) => {

        // TODO Validate Data
        if(user === null)
            user = {}

        user.email = username;
        user.username = username;
        user.password = password;
        user.roles = roles;
        user.reportMailUser = reportMailUser;

        setUser(user);

        e.preventDefault()

        onSubmitUserForm();
    }

    return (
        <form className='user-form' onSubmit={onSubmit}>
        <div id="locationData" className="row">
            <div className="six columns">
                <h5>Nutzer bearbeiten</h5>
            </div>
            <div className="six columns">
                <input type="submit" value="Speichern" className="btn u-pull-right btn-sm" />
                <button className='btn btn-sm u-pull-right' onClick={() => setCurrentView('users')}>Zurück</button>
                {displaySaveCallback ? <span className="successLabel u-pull-right">Gespeichert</span> : ''}
            </div>

            <div className="twelve columns">
                <hr/>
            </div>
        </div>
            <div className={'row'}>

                {displayErrorCallback ? <div className="alert-danger alert">Es gibt bereits einen Nutzer mit dieser E-Mail Adresse.<br/>Bitte prüfen Sie Ihre Angaben.</div> : ''}
                    <div className="six columns">

                        <div className='form-control'>
                            <label htmlFor="email">E-Mail</label>
                            <input className='u-full-width' type="email" placeholder='E-Mail' value={username} onChange={(e) => setUsername(e.target.value)} />
                        </div>

                        <div className='form-control'>
                            <label htmlFor="password">Passwort</label>
                            <input id={'password'} className='u-full-width' type="password" placeholder="Passwort" value={password} onChange={(e) => setPassword(e.target.value)} />
                        </div>
                        <div className='form-control'>
                            <label htmlFor={'roleAdmin'}>
                                <input id={'roleAdmin'} type="checkbox" key="roleAdmin" value="ROLE_ADMIN"  onChange={handleChange} />
                                &nbsp;Administrator
                            </label>
                        </div>
                        <div className='form-control'>
                            <label htmlFor={'reportMailUser'}>
                                <input id={'reportMailUser'} type="checkbox" key="reportMailUser" value="1" onChange={(e) => {
                                    setReportMailUser(e.target.checked)
                                }} />
                                &nbsp;Report Mail Empfänger
                            </label>
                        </div>

                    </div>



        </div>
        </form>
    )
}

export default User