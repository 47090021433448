const Users  = ({users, setCurrentView, setUser}) => {
    const loadUser = (user) => {
        setCurrentView('user')
        setUser(user);

    }

    return (
        <div id="usersData" className="row">
            <div className="six columns">
                <h5>Nutzerverwaltung <button className='btn btn-sm' onClick={() => loadUser(null)}>Neu</button></h5>
            </div>
            <div className="six columns">
                <button className='btn btn-sm u-pull-right' onClick={() => setCurrentView('main')}>Home</button>
            </div>

            <div className="twelve columns">
                <hr/>
            </div>
            <div className="twelve columns">

                <table id="usersTable">
                    <thead>
                    <tr>
                        <th>Nutzer</th>


                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    {users.map((user) => (
                        <tr key={user.id} >
                            <td>{user.email}</td>
                            <td className="text-right">
                                <button className='btn btn-sm u-pull-right' onClick={() => loadUser(user)}>Bearbeiten</button>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        </div>

    )

}

export default Users