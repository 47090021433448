import NumberFormat from 'react-number-format';
const Location  = ({location, setCurrentView, setLocation}) => {
    const loadView = () => {
        setLocation(location)
        setCurrentView('location')
    };

    return (
        <tr key={location.id}>
            <td>{location.title} 22</td>
            <td>{location.street}</td>
            <td>{location.postcode} {location.city}</td>
            <td>{location.countryName}</td>
            <td ><NumberFormat value={location.monthly_premium_amount_excl_tax} displayType={'text'} decimalSeparator={','} thousandSeparator={'.'} fixedDecimalScale={true} decimalScale={2}  suffix={' €'} /></td>
            <td ></td>
            <td ></td>
            <td><button className='btn btn-sm u-pull-right' onClick={loadView}>Übersicht</button></td>
        </tr>
    )

}

export default Location