import NumberFormat from 'react-number-format';

const AccountingSummaryData  = ({accountingSummary, accountingSummaryItems, location, setCurrentView, setBox}) => {

    const loadBox = (boxData) => {
        setCurrentView('box')
        setBox(boxData);
    }

    return (
        <div id="accountingSummaryData" className="row">

            <div className="six columns">
                <h5>{location.title} | {accountingSummary.month} / {accountingSummary.year} | Datum: {accountingSummary.transactionDate}</h5>
                Standort: {location.street} | {location.postcode} {location.city}
            </div>
            <div className="six columns">
                <button className='btn btn-sm u-pull-right' onClick={() => setCurrentView('main')}>Home</button>
                <button className='btn btn-sm u-pull-right' onClick={() => setCurrentView('location')}>{location.title}</button>
            </div>

            <div className="twelve columns">
                <hr/>
            </div>
            <div className="twelve columns">
            <table id={"accountingSummaryTable"}>
                <thead>
                <tr>
                    <th>Box</th>
                    <th>Start</th>
                    <th>Ende</th>
                    <th className="text-right">Versicherungssumme</th>
                    <th className="text-right">Zusatzprämie netto</th>
                    <th className="text-right">Steuersatz</th>
                    <th className="text-right">Zusatzprämie brutto</th>
                    <th></th>
                </tr>
                </thead>
                <tbody>
                {accountingSummaryItems.map((accounting) => (
                    <tr key={accounting.id} >
                        <td>{accounting.customerBoxNumber}</td>
                        <td >{accounting.startDate}</td>
                        <td>{accounting.endDate}</td>
                        <td className="text-right"><NumberFormat value={accounting.additionalInsuranceAmount} displayType={'text'} decimalSeparator={','} thousandSeparator={'.'} fixedDecimalScale={true} decimalScale={2}  suffix={' €'} /></td>
                        <td className="text-right"><NumberFormat value={accounting.monthlyPremiumAmount} displayType={'text'} decimalSeparator={','} thousandSeparator={'.'} fixedDecimalScale={true} decimalScale={2}  suffix={' €'} /></td>
                        <td className="text-right"></td>
                        <td className="text-right"></td>
                        <td><button className='btn btn-sm u-pull-right' onClick={() => loadBox({box: accounting.customerBoxNumber, locationId: location.id})}>Box {accounting.customerBoxNumber}</button></td>
                    </tr>
                ))}
                </tbody>
                <tfoot>
                <tr>
                    <th></th>
                    <th></th>
                    <th className="text-right">Summe</th>
                    <th className="text-right"><NumberFormat value={accountingSummary.additionalInsuranceAmount} displayType={'text'} decimalSeparator={','} thousandSeparator={'.'} fixedDecimalScale={true} decimalScale={2}  suffix={' €'} /></th>
                    <th className="text-right"><NumberFormat value={accountingSummary.monthlyPremiumAmountNetto} displayType={'text'} decimalSeparator={','} thousandSeparator={'.'} fixedDecimalScale={true} decimalScale={2}  suffix={' €'} /></th>
                    <th></th>
                    <th className="text-right"><NumberFormat value={accountingSummary.monthlyPremiumAmount} displayType={'text'} decimalSeparator={','} thousandSeparator={'.'} fixedDecimalScale={true} decimalScale={2}  suffix={' €'} /></th>
                    <th></th>
                </tr>
                </tfoot>
            </table>
            </div>
        </div>

    )

}

export default AccountingSummaryData