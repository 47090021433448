import { useState, useEffect } from 'react'
const Country = ({country, displaySaveCallback, onSubmitUserForm, setCurrentView, setCountry, displayErrorCallback}) => {

    const [taxRate, setTaxRate] = useState(country !== null ? country.taxRate : 0)
    const [countryName, setCountryName] = useState(country !== null ? country.countryName : '')

    const handleChange = (event) => {

    }

    useEffect(() => {

    }, [])


    const onSubmit = (e) => {

        // TODO Validate Data
        if(country === null)
            country = {}

        country.taxRate = taxRate;


        setCountry(country);

        e.preventDefault()

        onSubmitUserForm();
    }

    return (
        <form className='country' onSubmit={onSubmit}>
        <div id="countryData" className="row">
            <div className="six columns">
                <h5>{countryName} bearbeiten</h5>
            </div>
            <div className="six columns">
                <input type="submit" value="Speichern" className="btn u-pull-right btn-sm" />
                <button className='btn btn-sm u-pull-right' onClick={() => setCurrentView('countries')}>Zurück</button>
                {displaySaveCallback ? <span className="successLabel u-pull-right">Gespeichert</span> : ''}
            </div>

            <div className="twelve columns">
                <hr/>
            </div>
        </div>
            <div className={'row'}>

                {displayErrorCallback ? <div className="alert-danger alert">Es gab Probleme beim speicheern.<br/>Bitte prüfen Sie Ihre Angaben.</div> : ''}
                    <div className="six columns">

                        <div className='form-control'>
                            <label htmlFor="email">Steuersatz</label>
                            <input className='u-full-width' type="number" placeholder='Steuersatz' value={taxRate} onChange={(e) => setTaxRate(e.target.value)} />
                        </div>



                    </div>



        </div>
        </form>
    )
}

export default Country