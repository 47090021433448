import NumberFormat from 'react-number-format';
const LocationData  = ({location, accountingLocationSummary, setCurrentView, setAccountingSummary}) => {

    const loadAccounting = (accountingSummary) => {
        setCurrentView('accounting')
        setAccountingSummary(accountingSummary);
    }

    return (
        <div id="locationData" >
            <div className="row" >
                <div className="six columns">
                    <h5>{location.title}</h5>
                    Standort: {location.street} | {location.postcode} {location.city}
                </div>
                <div className="six columns">
                    <button className='btn btn-sm u-pull-right' onClick={() => setCurrentView('main')}>Home</button>
                </div>
            </div>
            <hr/>
            <div className="row" >
            <div className="three columns">
                <strong>Filter</strong>
            </div>
            <div className="three columns">
                <label for="period_start">Startmonat</label>
                <select id="period_start"  className="custom-datatables-filter">
                    <option value="0"></option>
                </select>
            </div>
            <div className="three columns">
                <label for="period_end">Endmonat</label>
                <select id="period_end" className="custom-datatables-filter">
                <option value="0"></option>
                </select>
            </div>
            <div className="three columns">
                <table>
                    <tbody>
                        <tr>
                            <td>Zusatzprämie netto</td>
                            <td className=" text-right"><strong><span id="premium_amount_netto"></span></strong></td>
                        </tr>
                        <tr>
                            <td>Zusatzprämie brutto</td>
                            <td className=" text-right"><strong><span id="premium_amount"></span></strong></td>
                        </tr>
                    </tbody>
                </table>

            </div>
            </div>
            
            <div className="row" >
            <div className="twelve columns">

            <table id="locationOverviewTable">
                <thead>
                <tr>
                    <th>Abrechnungszeitraum</th>
                    <th>Datum</th>
                    <th className="text-center">Transaktionen</th>
                    <th className="text-center">Schließfächer</th>
                    <th className="text-right">Versicherungssumme</th>
                    <th className="text-right">Zusatzprämie netto</th>
                    <th className="text-right">Steuersatz</th>
                    <th className="text-right">Zusatzprämie brutto</th>
                    <th></th>
                </tr>
                </thead>
                <tbody>
                {accountingLocationSummary.map((accountingSummary) => (
                    <tr key={accountingSummary.id} >
                        <td>{accountingSummary.month} / {accountingSummary.year}</td>
                        <td>{accountingSummary.transactionDate}</td>
                        <td className="text-center">{accountingSummary.countTransactions}</td>
                        <td className="text-center">{accountingSummary.box_quantity}</td>
                        <td className="text-right"><NumberFormat value={accountingSummary.additionalInsuranceAmount} displayType={'text'} decimalSeparator={','} thousandSeparator={'.'} fixedDecimalScale={true} decimalScale={2}  suffix={' €'} /></td>
                        <td className="text-right"><NumberFormat value={accountingSummary.monthlyPremiumAmount} displayType={'text'} decimalSeparator={','} thousandSeparator={'.'} fixedDecimalScale={true} decimalScale={2}  suffix={' €'} /></td>
                        <td></td>
                        <td></td>
                        <td>
                        <button className='btn btn-sm u-pull-right' onClick={() => loadAccounting(accountingSummary)}>Übersicht</button>
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>
            </div>
            </div>
        </div>

    )

}

export default LocationData