const Header = ({logOut, userRoles, isLoggedIn, setCurrentView, sandBoxUser}) => {
    return (
        <header className='header'>
            <div className="row">
                <div className="six columns"><img src='/img/rrvm-logo.png' alt='for broker Trisor App' className="nav-brand" onClick={() => setCurrentView('main')} style={{cursor: 'pointer'}} /></div>
                <div className="six columns ">
                    {isLoggedIn ? <button className='btn btn-sm u-pull-right' onClick={logOut}>Log Out</button> : ''}
                    {isLoggedIn && userRoles.indexOf('ROLE_ADMIN') !== -1 ? <button className='btn btn-sm u-pull-right' onClick={() => setCurrentView('countries')}>Länder</button> : ''}
                    {isLoggedIn && userRoles.indexOf('ROLE_ADMIN') !== -1 ? <button className='btn btn-sm u-pull-right' onClick={() => setCurrentView('users')}>Nutzer</button> : ''}

                    {sandBoxUser ? <span className="successLabel ">SANDBOX</span> : ''}
                </div>
            </div>
        </header>
    )
}

export default Header