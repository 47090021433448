import NumberFormat from 'react-number-format';
const BoxData  = ({boxAccounting, box, location, setCurrentView, setAccountingSummary, accountingSummary}) => {

    const loadAccounting = (accountingSummary) => {
        setCurrentView('accounting')
        setAccountingSummary(accountingSummary);

    }

    return (
        <div id="boxData" className="row">
            <div className="six columns">
                <h5>{location.title} | Box {box.box}</h5>
                Standort: {location.street} | {location.postcode} {location.city}
            </div>
            <div className="six columns">
                <button className='btn btn-sm u-pull-right' onClick={() => setCurrentView('main')}>Home</button>
                <button className='btn btn-sm u-pull-right' onClick={() => setCurrentView('location')}>{location.title}</button>
                <button className='btn btn-sm u-pull-right' onClick={() => loadAccounting(accountingSummary)}>Übersicht</button>
            </div>

            <div className="twelve columns">
                <hr/>
            </div>
            <div className="twelve columns">

            <table id="boxDataTable">
                <thead>
                <tr>
                    <th>Box</th>
                    <th>Start</th>
                    <th>Ende</th>
                    <th className="text-right">Versicherungssumme</th>
                    <th className="text-right">Zusatzprämie netto</th>
                    <th className="text-right">Steuersatz</th>
                    <th className="text-right">Zusatzprämie brutto</th>
                </tr>
                </thead>
                <tbody>
                {boxAccounting.map((accounting) => (
                    <tr key={accounting.id} >
                        <td>{accounting.customerBoxNumber}</td>
                        <td>{accounting.startDate}</td>
                        <td>{accounting.endDate}</td>
                        <td className="text-right"><NumberFormat value={accounting.additionalInsuranceAmount} displayType={'text'} decimalSeparator={','} thousandSeparator={'.'} fixedDecimalScale={true} decimalScale={2}  suffix={' €'} /></td>
                        <td className="text-right"><NumberFormat value={accounting.monthlyPremiumAmountNetto} displayType={'text'} decimalSeparator={','} thousandSeparator={'.'} fixedDecimalScale={true} decimalScale={2}  suffix={' €'} /></td>
                        <td className="text-right"><NumberFormat value={accounting.taxRate} displayType={'text'} decimalSeparator={','} thousandSeparator={'.'} fixedDecimalScale={true} decimalScale={2}  suffix={'%'} /></td>
                        <td className="text-right"><NumberFormat value={accounting.monthlyPremiumAmount} displayType={'text'} decimalSeparator={','} thousandSeparator={'.'} fixedDecimalScale={true} decimalScale={2}  suffix={' €'} /></td>
                    </tr>
                ))}
                </tbody>
            </table>
            </div>
        </div>

    )

}

export default BoxData