import Location from "./Location";

const Content  = ({locations, setCurrentView, setLocation}) => {

    return (
        <div id="main" className="row">
            <div className="six columns">
                <h5>Trisor Standorte</h5>

            </div>
            <div className="six columns">

            </div>

            <div className="twelve columns">
                <hr/>
            </div>
            <div className="row" >
                <div className="three columns">
                    <strong>Filter</strong>
                </div>
                <div className="three columns">
                    <label for="period_start">Startmonat</label>
                    <select id="period_start"  className="custom-datatables-filter">
                        <option value="0"></option>
                    </select>
                </div>
                <div className="three columns">
                    <label for="period_end">Endmonat</label>
                    <select id="period_end" className="custom-datatables-filter">
                    <option value="0"></option>
                    </select>
                </div>
                <div className="three columns">
                <table>
                    <tbody>
                        <tr>
                            <td>Zusatzprämie netto</td>
                            <td className=" text-right"><strong><span id="premium_amount_netto"></span></strong></td>
                        </tr>
                        <tr>
                            <td>Zusatzprämie brutto</td>
                            <td className=" text-right"><strong><span id="premium_amount"></span></strong></td>
                        </tr>
                    </tbody>
                </table>
                </div>
            </div>
            <div className="twelve columns">
                <table id="locations" className="display u-max-full-width" >
                    <thead>
                    <tr>
                        <th>Name</th>
                        <th>Adresse</th>
                        <th>Stadt</th>
                        <th>Land</th>
                        <th className="text-right">Zusatzprämie netto</th>
                        <th className="text-right">Steuersatz</th>
                        <th className="text-right">Zusatzprämie brutto</th>
                        <th></th>

                    </tr>
                    </thead>
                    <tbody>
                    {locations.map((location) => (
                        <Location key={location.id} location={location} setCurrentView={setCurrentView} setLocation={setLocation}  />
                    ))}
                    </tbody>
                </table>
            </div>

        </div>

    )

}

export default Content