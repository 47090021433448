const Countries  = ({countries, setCurrentView, setCountry}) => {
    const loadCountry = (country) => {
        setCurrentView('country')
        setCountry(country);

    }

    return (
        <div id="usersData" className="row">
            <div className="six columns">
                <h5>Länderverwaltung</h5>
            </div>
            <div className="six columns">
                <button className='btn btn-sm u-pull-right' onClick={() => setCurrentView('main')}>Home</button>
            </div>

            <div className="twelve columns">
                <hr/>
            </div>
            <div className="twelve columns">

                <table id="countryTable">
                    <thead>
                    <tr>
                        <th>Land</th>


                        <th>Kürzel</th>
                        <th className="text-right">Steuersatz</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    {countries.map((country) => (
                        <tr key={country.id} >
                            <td>{country.countryName}</td>
                            <td>{country.countryCode}</td>
                            <td className="text-right">{country.taxRate}%</td>
                            <td className="text-right">
                                <button className='btn btn-sm u-pull-right' onClick={() => loadCountry(country)}>Bearbeiten</button>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        </div>

    )

}

export default Countries